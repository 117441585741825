<template>
        <hb-report  :key="report_key_na" report_type="active_national_accounts"
                :actions_panel="['advanced', 'filters', 'export', 'bulk_edit', 'columns', 'save']" show_views
                right_click row_click show_search @rowClicked="viewProfile"
                :show_default_report_option="true"></hb-report>
</template>
<script>
import HbReport from '../assets/HummingbirdReportViewer.vue';
import { notificationMixin } from  '../../mixins/notificationMixin.js';
export default {
    name: "NationalAccountReport",
    expose: ['fetchNationalData'],
    mixins: [ notificationMixin ],
    components: {
        HbReport
    },
    data() {
        return {
            report_key_na : 0,
        }
    },
    methods: {
        fetchNationalData() {
            this.report_key_na++;
        },
        viewProfile(data) {
            if(data.na_status!="Inactive"){
                let path = `/national-account/${data.tenant_id}`;
            path+=`?na_id=${data.na_id}`
            path += `&dynamicRun=true`;
            if (data.unit_id) path += `?unit_id=${data.unit_id}`
            if (data.tenant_id) this.$router.push(path);
            }else{
                console.log("in active ")
                this.showMessageNotification({type: 'error', description: 'National account is closed'});
            }
           
            
            
        }
    }
}
</script>

<style scoped></style>