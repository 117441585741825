<template>
    <div class="section-content pt-4" :class="{ 'mt-10': $vuetify.breakpoint.xs, 'mt-n3': $vuetify.breakpoint.sm }">
        <hb-header full :divider=false>
            <hb-page-header title="Tenants" :description="showTabs ? 'Manage tenants and national accounts.' : ''">
            </hb-page-header>
        </hb-header>
        <hb-header fewer-actions :padding="false" align-top class="mt-n2" :divider=showTabs>
            <template v-slot:left>
                <hb-tabs v-model="activeTab" v-if="showTabs">
                    <v-tab v-for="menuOption in computedMenuOptions" :key="menuOption.key" :to="menuOption.route"
                        @click="viewSelector(menuOption)" :ripple="false">{{ menuOption.label }}</v-tab>
                </hb-tabs>
            </template>
        </hb-header>
        <!-- <router-view></router-view> -->
        <div style="flex: 1" :class="{'filter-adjustment-hb-report': $vuetify.breakpoint.xs}">
            <hb-report :key="report_key" report_type="active_tenants" v-if="view == 'Tenants'"
                :actions_panel="actions_panel_list" show_views
                right_click row_click show_search :column-conf="columnConf" @rowClicked="viewLease"
                :show_default_report_option="true"></hb-report>
            <national-account-report ref="NationalAccountReport"
                v-else-if="view == 'NationalAccounts'"></national-account-report>

            <national-account v-if="showTabs" @refresh="callReport"></national-account>
        </div>
        <hb-modal
        v-model="showNoPermissionDialogNationalAccountManage"
        size="medium"
        title="You Don’t Have Permission"
        :footerOff="true"
        confirmation
        @close="showNoPermissionDialogNationalAccountManage = false"
        show-help-link
    >
        <template v-slot:content>
        <div class="px-6 py-4">
            <div class="pb-2">
                You do not have the required permission to perform this action.
            </div>
            <div>
                Required Permission: 
                <span class="hb-font-body-medium">
                    Manage National Account
                </span>
            </div>
        </div>
        </template>
        </hb-modal>
    </div>
</template>

<script type="text/babel">
import HbReport from '../assets/HummingbirdReportViewer.vue';
import StatusCellRenderer from '@/components/BI/StatusCellRenderer.vue'
import NationalAccount from './NationalAccount.vue';
import NationalAccountReport from './NationalAccountReport.vue';
import { mapGetters, mapActions } from "vuex";
import { EventBus } from '../../EventBus';

export default {
    name: "Tenants",
    data() {
        return {
            activeTab: '',
            showTabs: true,
            report_key: 0,
            report_key_na: 1,
            view: this.$route.name,
            showNoPermissionDialogNationalAccountManage:null
        }
    },
    components: {
        HbReport,
        StatusCellRenderer,
        NationalAccount,
        NationalAccountReport
    },
    created() {
        EventBus.$on('refetch_data', () => this.fetchData());
    },
    destroyed() {
        EventBus.$off('refetch_data', () => this.fetchData());
    },
    filters: {

    },
    mounted() {
        this.view = this.$route.name
    },
    computed: {
        ...mapGetters({
            hasPermission: "authenticationStore/rolePermission",
            getSettings: 'authenticationStore/getSettings',
        }),
        columnConf() {
            return {
                lease_rent_plan_status: {
                    cellRenderer: 'StatusCellRenderer',
                    cellRendererParams: {
                        leaseStatus: true,
                        general: true
                    },
                },
            }
        },
        computedMenuOptions() {
            // risk string matching 
            if (this.getSettings?.nationalAccountFeature === '0') {
                this.showTabs = false; //remove tab
                this.view == 'Tenants'; // makes defalut view tenant
            }
            else {
                this.showTabs = true;
                return [
                    {
                        key: "Tenants",
                        label: "Tenants",
                        route: '/tenants'
                    },
                    {
                        key: "NationalAccounts",
                        label: "National Accounts",
                        route:'/national-accounts',
                    },
                ]
            }
        },
        actions_panel_list() {
            if(this.$vuetify.breakpoint.xs) {
                return ['filters', 'export', 'save'];
            } else {
                return ['advanced', 'filters', 'export', 'bulk_edit', 'columns', 'save'];
            }
        }
    },
    methods: {
        viewSelector(menuOption){
            if(menuOption.key == "NationalAccounts" && !this.hasPermission("manage_national_accounts") ){
                  this.label = "Tenants"
                  this.showNoPermissionDialogNationalAccountManage = true
                  this.activeTab = "Tenants";                
                }else{
                this.view = menuOption.key
            }
            
        },
        fetchData() {
            this.report_key++;
        },
        viewLease(data) {
            if (data.na_id) {
                let path = `/national-account/${data.tenant_id}/account-profile`;
                path += `?na_id=${data.na_id}`
                if (data.unit_id) path += `&unit_id=${data.unit_id}`
                if (data.tenant_id) this.$router.push(path);
            } else {
                let path = `/contacts/${data.tenant_id}`;
                if (data.unit_id) path += `?unit_id=${data.unit_id}`
                if (data.tenant_id) this.$router.push(path);
            }
        },
        callReport() {
            this.$refs.NationalAccountReport.fetchNationalData()
        }
    },

    watch: {
    }

}
</script>
<style lang="scss"> 
.filter-adjustment-hb-report {
        .report-viewer-container {
            > div:nth-child(1) {
                > span > div > div > div {
                    > div:nth-child(1) {
                        width: 50%;
                        margin-bottom: 6px !important;
                        .hb-autocomplete-wrapper {
                            width: unset !important;
                        }
                    }
                    > div:nth-child(2) {
                        display: none !important;
                    }
                    > div:nth-child(3) {
                        margin-bottom: 6px !important;
                        width: 50%;
                        .hb-text-field-wrapper {
                            width: unset !important;
                        }
                    }
                    > div:nth-child(4) {
                        margin-bottom: 0px !important;
                        margin-top: 0px !important;
                    }
                }
            }
        }
    }
</style>
<style scoped></style>
