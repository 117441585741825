<template>
    <div class="national-account-main-wrapper">
        <hb-modal title="Add National Account" v-model="createAccountPopUp" @close="modalClose">

            <template v-slot:subheader>
                Create a new National Account by providing the information below
            </template>

            <template v-slot:content>
                <div class="text-field-wrapper-parent">
                <hb-form label="Company Name" required>
                    <HbTextField class="mt-1" v-model="formdata.name"
                        v-validate="{ required: true, regex: /^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/ }"
                        data-vv-scope="default" data-vv-name="company-name" data-vv-as="Company Name"
                        :error="errors.has('default.company-name')" placeholder="Company Name" />
                </hb-form>
                <hb-form label="Trading Name" description="Provide the trading name of the company.">
                    <HbTextField class="mt-1" v-model="formdata.trading_name"
                        v-validate="{ regex: /^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/ }" data-vv-scope="default"
                        data-vv-name="trading_name" data-vv-as="Trading Name"
                        :error="errors.has('default.trading_name')" placeholder="Trading Name" />
                </hb-form>
                <hb-form label="Country" required>
                    <HbSelect v-model="formdata.address.country" :items="countryList" v-validate="{ required: true }"
                        item-text="label" item-value="key" placeholder="Select Country" data-vv-scope="default"
                        data-vv-name="country" data-vv-as="Country" :error="errors.has('default.country')"
                        @change="setCountry()" />
                </hb-form>
                <hb-form label="Business Number" required
                    :description="formdata.address.country === 'Australia' ? 'Provide an ABN.' : formdata.address.country === 'New Zealand' ? 'Provide a NZBN' : 'Provide a Business Number.'">
                    <HbTextField v-model="formdata.taxId" v-validate="taxIdValidationRule" placeholder="Business Number"
                        data-vv-scope="default" data-vv-name="bNumber" data-vv-as="Business Number"
                        :hide-details="!errors.collect('default.bNumber').length" :error="errors.has('default.bNumber')"
                        data-vv-validate-on="'blur'" :disabled="!formdata.address.country" />
                </hb-form>
                <hb-form label="Business Address">
                    <HbTextField class="mt-1" v-model="formdata.address.address" data-vv-scope="default"
                        data-vv-name="street-address" data-vv-as="Street Addresssss"
                        :error="errors.has('default.street-address')" label="Street Address" />
                    <v-row no gutters>
                        <v-col cols="4" class="mt-1">
                            <HbAutocomplete condensed v-model="formdata.address.city" :items="cityListMixin[0]"
                                single-line v-validate="'max:45'" data-vv-scope="default" data-vv-name="city"
                                data-vv-as="city" :error="errors.has('default.city')" item-text='name'
                                item-value='value' placeholder="City" />
                        </v-col>
                        <v-col cols="4">
                            <HbAutocomplete condensed class="mt-1" v-model="formdata.address.state"
                                :items="stateListMixin[0] || []" single-line data-vv-scope="default"
                                data-vv-name="State" data-vv-as="state" item-text='name' item-value='value'
                                :error="errors.has('default.state')" placeholder="State" />
                        </v-col>
                        <v-col cols="4" class="mt-1">
                            <HbTextField v-model="formdata.address.zip" class="mt-1" data-vv-scope="default" single-line
                                data-vv-name="street-address" data-vv-as="Zip" :error="errors.has('default.zip')"
                                label="ZIP" />
                        </v-col>
                    </v-row>
                </hb-form>
                <hb-form label="Primary Contact" required full>
                    <v-row>
                        <v-col>
                            <HbTextField v-model="formdata.contact.first" v-validate="{ required: true }"
                                data-vv-scope="default" data-vv-name="contact-first-name" data-vv-as="FirstName"
                                label="FirstName" :error="errors.has('default.contact-first-name')" />
                        </v-col>
                        <v-col>
                            <HbTextField v-model="formdata.contact.last" v-validate="'required|max:45'"
                                data-vv-scope="default" data-vv-name="contact-second-name" data-vv-as="LastName"
                                label="SecondName" :error="errors.has('default.contact-second-name')" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols='8'>
                            <HbTextField v-model="formdata.contact.email" class="mt-n4" v-validate="'required|max:45|email'"
                        data-vv-scope="default" data-vv-name="contact-email" data-vv-as="Email"
                        :error="errors.has('default.contact-email')" label="Contact Email" />
                        </v-col>
                        <v-col>
                            <HbCheckbox v-model="formdata.contact.send_invoices_email" label="Email Invoice" />
                        </v-col>
                    </v-row>
                    <HbSelect v-model="formdata.contact.Phones[0].type" class="mt-5" v-validate="'required'"
                        :items="['Phone', 'Cell', 'Home', 'Work', 'Fax', 'Other']" data-vv-scope="default"
                        data-vv-name="phone" data-vv-as="Phone" :error="errors.has('default.phone')"
                        placeholder="Select Phone" />
                    <v-row no gutters>
                        <v-col cols="3" class="mt-2">
                            <hb-autocomplete v-model="phoneCode" box :items="countryCodesList" label="Code"
                                data-vv-scope="default" data-vv-name="Code" data-vv-as="phone code"
                                :error="errors.has('default.Code') > 0" :clearable="false" v-validate="'required'"
                                @change="checkValidPhone(phoneCode, Phone)"
                                @focusout="checkValidPhone(phoneCode, Phone)">
                                <template v-slot:selection="data">
                                    <span class="mb-2">+{{ data.item }}</span>
                                </template>

                                <template v-slot:item="data">
                                    <span class="mb-3">+{{ data.item }}</span>
                                </template>
                            </hb-autocomplete>
                        </v-col>
                        <v-col cols="6" lg="4" class="mt-5 pa-0"
                            :class="{ 'pl-3': $vuetify.breakpoint.lgAndUp, 'mt-3': $vuetify.breakpoint.mdAndDown }">
                            <HbTextField v-model="Phone" box v-validate="'required|max:45'"
                                placeholder="Phone Number" v-mask="phoneMask(phoneCode)"
                                data-vv-scope="default" data-vv-name="number" data-vv-as="Phone Number"
                                @change="checkValidPhone(phoneCode, Phone)"
                                @focusout="checkValidPhone(phoneCode, Phone)"
                                :hide-details="!errors.collect('default.number').length"
                                :error="errors.collect('default.number').length > 0 || !checkValidPhone(phoneCode, Phone) ? true : false" />
                        </v-col>
                        <v-col class="mt-4 ml-2"
                            :class="{ 'pl-2': $vuetify.breakpoint.lgAndUp, 'mt-3': $vuetify.breakpoint.mdAndDown }">
                            <HbCheckbox v-model="formdata.contact.Phones[0].sms" label="Receive Texts?" />
                        </v-col>
                    </v-row>
                </hb-form>
                <hb-form label="Sqm Discount" description="Choose a discount to apply to this National Account.">
                    <HbSelect v-model="formdata.promotion_id" :items="discounts" item-text="label" item-value="key"
                        data-vv-scope="" data-vv-name="discount" data-vv-as="discount"
                        :error="errors.has('default.discount')" @click="getDiscount()" />
                </hb-form>
                <hb-form label="Departments" full>
                    <v-row class="mb-0">
                        <v-col cols="8">
                            <HbTextField v-model="departmentName" box data-vv-scope="department"
                                data-vv-name="department-name" data-vv-as="department Name"
                                v-validate="'required|max:60'" :error="errors.has('department.department-name')"
                                placeholder="Enter Department Name" />
                        </v-col>
                        <v-col cols="4">
                            <hb-btn color="secondary" medium prepend-icon="mdi-plus"
                                @click="addDepartment()">Add</hb-btn>
                        </v-col>
                    </v-row>
                    <HbTextField full readonly>
                        <template v-slot:prepend-inner>
                            <div class="chip-block custom-chip-block">
                            <hb-chip v-for="( item, i ) in formdata.departments " :key="'editable_chip_' + i"
                                @click="handleChipCloseClick(item.name)" pointer :editable="true">
                                {{ item.name }}
                            </hb-chip>
                            </div>
                        </template>
                    </HbTextField>
                </hb-form>
                <hb-form label="PO Number" description="Provide a PO number for this National Account.">
                    <HbTextField class="mt-1" v-model="formdata.po_number" v-validate="{ regex: /^[\x20-\x7E]{1,45}$/ }"
                        data-vv-scope="default" data-vv-name="po_number" data-vv-as="PO Number"
                        :error="errors.has('default.po_number')" placeholder="PO Number" />
                </hb-form>
                <hb-form label="Billing Cycle" required
                    description="Select the billing cycle for the National Account.">
                    <HbSelect v-model="formdata.billing_cycle" :items="billingCycle" v-validate="{ required: true }"
                        item-text="label" item-value="key" placeholder="Select billingCycle" data-vv-scope="default"
                        data-vv-name="billingCycle" data-vv-as="billingCycle"
                        :error="errors.has('default.billingCycle')" />
                </hb-form>
                <hb-form label="Next Review Date" required>
                    <HbDatePicker v-model="formdata.next_review_date" @click:clear="formdata.next_review_date = null"
                        v-validate="'required'" label="MM/DD/YYYY" :clearable="true" :min="new Date().toISOString()"
                        data-vv-scope="default" clear-icon="mdi-close-circle" :solo="false" :single-line="true"
                        data-vv-name="next_review_date" data-vv-as="Review date"
                        :error="errors.collect('default.next_review_date').length > 0" />
                </hb-form>
            </div>
            </template>

            <template v-slot:right-actions>
                <hb-btn @click="createNationalAccount" :loading="loading">Save</hb-btn>
            </template>
        </hb-modal>
    </div>
</template>

<script>
import { notificationMixin } from "@/mixins/notificationMixin.js";
import { EventBus } from '../../EventBus'
import { countryStateCityMixin } from '../../mixins/countryStateCityMixin';
import { parsePhoneNumber } from 'libphonenumber-js';
import api from '../../assets/api.js';
import HbDatePicker from '../assets/HummingbirdDatepicker.vue';
import HbReport from '../assets/HummingbirdReportViewer.vue';
export default {
    name: "NationalAccount",
    mixins: [countryStateCityMixin, notificationMixin],
    components: {
        HbDatePicker,
        HbReport
    },
    data() {
        return {
            report_key_na: 0,
            loading: false,
            searchAcc: '',
            countryCodesList: ["1", "7", "20", "27", "30", "31", "32", "33", "34", "36", "39", "40", "41", "43", "44", "45", "46", "47", "48", "49", "51", "52", "53", "54", "55", "56", "57", "58", "60", "61", "62", "63", "64", "65", "66", "81", "82", "84", "86", "90", "91", "92", "93", "94", "95", "98", "211", "212", "213", "216", "218", "220", "221", "222", "223", "224", "225", "226", "227", "228", "229", "230", "231", "232", "233", "234", "235", "236", "237", "238", "239", "240", "241", "242", "243", "244", "245", "246", "247", "248", "249", "250", "251", "252", "253", "254", "255", "256", "257", "258", "260", "261", "262", "263", "264", "265", "266", "267", "268", "269", "290", "291", "297", "298", "299", "350", "351", "352", "353", "354", "355", "356", "357", "358", "359", "370", "371", "372", "373", "374", "375", "376", "377", "378", "380", "381", "382", "383", "385", "386", "387", "389", "420", "421", "423", "500", "501", "502", "503", "504", "505", "506", "507", "508", "509", "590", "591", "592", "593", "594", "595", "596", "597", "598", "599", "670", "672", "673", "674", "675", "676", "677", "678", "679", "680", "681", "682", "683", "685", "686", "687", "688", "689", "690", "691", "692", "850", "852", "853", "855", "856", "880", "886", "960", "961", "962", "963", "964", "965", "966", "967", "968", "970", "971", "972", "973", "974", "975", "976", "977", "992", "993", "994", "995", "996", "998"],
            createAccountPopUp: false,
            invalidPhone: null,
            phoneCode: '',
            Phone: '',
            formdata: {
                name: '',
                trading_name: '',
                taxId: '',
                address: {
                    country: null,
                    address: '',
                    city: '',
                    state: '',
                    zip: ''
                },
                contact: {
                    first: '',
                    last: '',
                    email: '',
                    Phones: [
                        {
                            phone: '',
                            type: '',
                            sms: false
                        }
                    ]
                },
                promotion_id: '',
                departments: [],
                po_number: '',
                next_review_date: '',
                billing_cycle: 'monthly'
            },
            departmentName: '',
            discounts: [

            ],
            countryList: [
                { key: "Australia", label: 'Australia' },
                { key: "New Zealand", label: 'New Zealand' }
            ],
            loading: false,
            billingCycle: [
                { key: "monthly", label: 'Monthly' },
                { key: "quarterly", label: 'Quarterly' },
                { key: "semi-annual", label: 'Semi Annual' },
                { key: "annual", label: 'Annual' },
            ]
        }
    },
    computed: {
        headers() {
            let headers = [
                { text: "Name", value: "name" },
                { text: "Sqm Discount", value: "discount" },
                { text: "Primary Contact", value: "primary_contact" },
                { text: "# Spaces", value: "space_count" },
                { text: "Country", value: "country" },
                { text: "Status", value: "status" },
                { text: "", value: "actions", align: "right", sortable: false, width: 10 }
            ];
            return headers;
        },
        taxIdValidationRule() {
            if (this.formdata.address.country === 'Australia') {
                return 'required|length:13|numeric';
            } else if (this.formdata.address.country === 'New Zealand') {
                return 'required|length:11|numeric';
            } else {
                return 'required|numeric'; // Default rule
            }
        }
    },
    created() {
        this.setCountriesListEvent()
    },
    destroyed() {
        EventBus.$off("openNationalAccount")
    },
    mounted() {
        this.formdata.address.country = 'Australia'
        this.setCountry()
        EventBus.$on("openNationalAccount", this.opemModal);
    },
    methods: {
        async getDiscount() {
            await api.get(this, api.PROMOTION).then(async (res) => {
                this.discounts = res.promotions.map(promotion => ({
                    key: promotion.id,
                    label: `${promotion.name} - ${promotion.value}%`
                }));
            })
        },
        fetchNationalData() {
            this.report_key_na++;
        },
        opemModal() {
            this.createAccountPopUp = true
            this.resetForm()
        },
        modalClose() {
            this.resetForm();
            this.$validator.reset()
        },
        setCountry() {
            this.formdata.address.city = ''
            this.formdata.address.state = ''
            this.setStatesListEvent(this.formdata.address.country, 0)
            this.setCountryCitiesListEvent(this.formdata.address.country, 0)
        },
        phoneMask(code) {
            switch (parseInt(code)) {
                case 1:
                    return "(###) ###-####";
                default:
                    return "";
            }
        },
        validateEmailPhone() {
            if (!this.formdata.contact.email && (!this.Phone)) {
                return true
            }
            return false
        },
        checkValidPhone(code, number) {
            const emailPhoneError = "Either Email or Phone is required"
            const emailPhoneRequired = this.errors.items.indexOf(emailPhoneError)

            if (!this.validateEmailPhone() && emailPhoneRequired >= 0) {
                this.errors.items.splice(emailPhoneRequired);
            }
            if (code && number) {
                var parsedPhoneNumber = parsePhoneNumber('+' + code + number);

                var r = null;
                if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
                    this.invalidPhone = false;
                    r = true;
                } else if ((!parsedPhoneNumber || (parsedPhoneNumber && !parsedPhoneNumber.isValid())) && number?.length > 0) {
                    this.invalidPhone = true;
                    r = false;
                } else {
                    this.invalidPhone = false;
                    r = true;
                }
                return r;
            }
            return true
        },
        resetForm() {
            this.formdata = {
                name: '',
                trading_name: '',
                taxId: '',
                address: {
                    country: '',
                    address: '',
                    city: '',
                    state: '',
                    zip: ''
                },
                contact: {
                    first: '',
                    last: '',
                    email: '',
                    Phones: [
                        {
                            phone: '',
                            type: '',
                            sms: false
                        }
                    ]
                },
                promotion_id: '',
                departments: [],
                po_number: '',
                next_review_date: '',
                billing_cycle: 'monthly'
            };
            this.Phone = ''
            this.phoneCode = ''

            // this.formdata.departments.push({ "id": (Math.random() + 1).toString(36).substring(7), "name": "Defalut", "lease": "defalut_lease" })
            this.$validator.reset()
        },
        addNationalAccount() {
            this.createAccountPopUp = true
            this.resetForm();
        },
        async createNationalAccount() {
            let general_status = await this.$validator.validateAll('default');
            if (!general_status) {
                this.showMessageNotification();
                return;
            }
            else {
                let parsedPhoneNumber = parsePhoneNumber('+' + this.phoneCode + this.Phone);
                if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
                    this.formdata.contact.Phones[0].phone = parsedPhoneNumber.countryCallingCode + parsedPhoneNumber.nationalNumber;

                } else {
                    this.showMessageNotification({ type: 'error', description: 'Please Enter a Valid Phone Number.' });
                    return;
                }
                try {
                    this.loading = true
                    // delete this.phoneCode
                    // this.formdata.departments.push({ "name": this.formdata.name , default_department: true})
                    await api.post(this, api.NATIONAL_ACCOUNT, this.formdata).then(async () => {
                        this.loading = false
                        this.resetForm()
                        this.$validator.reset()
                        this.$emit('refresh');
                        this.showMessageNotification({ type: 'success', description: "Account Created Successfully" });
                        this.createAccountPopUp = false // close  modal after success
                    })
                } catch (error) {
                    this.loading = false
                    this.$validator.reset()
                    // this.formdata.departments = this.formdata.departments.filter((department) => {
                    //     return department.name != this.formdata.name
                    // })
                    this.showMessageNotification({ type: 'error', description: error }); //  error message
                }
            }
        },
        viewProfile(data) {
            let path = `/contacts/${data.tenant_id}`;
            if (data.unit_id) path += `?unit_id=${data.unit_id}`
            if (data.tenant_id) this.$router.push(path);
        },
        async addDepartment() {
            let department_status = await this.$validator.validate('department.department-name');
            if (!department_status) {
                this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                return;
            } else {
                console.log(this.formdata.departments);
                // Check if the maximum number of departments (20) has been reached
                if (this.formdata.departments?.length >= 20) {
                    this.showMessageNotification({ type: 'error', description: 'You can only create a maximum of 20 departments.' });
                    return;
                } else {
                    // Trim and check if departmentName already exists in departments array
                    let trimmedDepartmentName = this.departmentName.trim();
                    if (!this.formdata.departments.some(department => department.name.trim() === trimmedDepartmentName)) {
                        this.formdata.departments.push({ "name": trimmedDepartmentName });
                        this.departmentName = '';
                    } else {
                        this.showMessageNotification({ type: 'error', description: 'Department already exists.' });
                    }
                }
            }
        },
        handleChipCloseClick(departmentKeyToRemove) {
            // Find the index of the department to remove
            const departmentIndex = this.formdata.departments.findIndex(dept => dept.name === departmentKeyToRemove);
            // If the department exists 
            if (departmentIndex !== -1) {
                // Remove the department from formdata.departments
                this.formdata.departments.splice(departmentIndex, 1);
            }
        },
    }
}
</script>

<style lang="css" scoped>
.text-field-wrapper-parent >>>.hb-text-field-wrapper .v-input__prepend-inner .custom-chip-block .v-icon{
    margin-bottom: 0px !important
  }
</style>
